import { render, staticRenderFns } from "./DriverList.vue?vue&type=template&id=84534f16&scoped=true"
import script from "./DriverList.vue?vue&type=script&lang=js"
export * from "./DriverList.vue?vue&type=script&lang=js"
import style0 from "./DriverList.vue?vue&type=style&index=0&id=84534f16&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/.store/vue-loader@15.11.1/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "84534f16",
  null
  
)

export default component.exports