<script>
import { Icon } from 'ant-design-vue'
import STable from '@/components/Table/'
import { changeUserMembership, deleteSharePermission, loadPermissionList } from '@/api/data'
import QrBind from '@/views/data/modal/QrBind.vue'
import EmailBind from '@/views/data/modal/EmailBind.vue'
import UserProfile from '@/views/data/modal/UserProfile.vue'
import MembershipUpgrade from '@/views/data/modal/MembershipUpgrade.vue'
import moment from 'moment'

const IconFont = Icon.createFromIconfontCN({
  scriptUrl: '//at.alicdn.com/t/c/font_4667817_6r4uyhchvik.js'
})
export default {
  name: 'DriverList',
  components: {
    STable,
    IconFont
  },
  data () {
    return {
      expireNum: 0,
      col: {
        lg: 8,
        xl: 6
      },
      locale: {
        emptyText: this.$t('table.emptyText')
      },
      columns: [{
        title: this.$t('user.name'),
        dataIndex: 'name',
        align: 'center',
        key: 'name'
      }, {
         title: this.$t('user.account'),
        dataIndex: 'account',
        align: 'center',
        key: 'account'
      }, {
        title: this.$t('user.membership.level'),
        dataIndex: 'level',
        align: 'center',
        width: 200,
        scopedSlots: { customRender: 'level' },
        key: 'level'
      }, {
        title: this.$t('user.membership.expire.time'),
        dataIndex: 'level_expire',
        align: 'center',
        width: 160,
        scopedSlots: { customRender: 'expire' },
        key: 'level'
      }, {
        title: this.$t('user.share.status'),
        dataIndex: 'status',
        align: 'center',
        width: 100,
        scopedSlots: { customRender: 'status' },
        key: 'status'
      }, {
        title: this.$t('user.bindTime'),
        dataIndex: 'create_time',
        align: 'center',
        width: 160,
        key: 'create_time'
      }, {
        title: this.$t('user.action'),
        dataIndex: 'action',
        scopedSlots: { customRender: 'action' },
        width: 310,
        fixed: 'right',
        align: 'center',
        key: 'action'
      }],
      queryParams: {
        name: ''
      },
      loadData: (paramter) => {
          return loadPermissionList(Object.assign(this.queryParams, paramter)).then(res => {
            return res.result
        })
      }
    }
  },
  created () {
    this.checkExpireNum()
  },
  methods: {
    moment,
    refreshTable () {
      this.$refs.table.refresh()
      this.checkExpireNum()
    },
    checkExpireNum () {
      loadPermissionList({ pageNo: 1, pageSize: 100 }).then(res => {
        const ds = res.result.data
        let expireNum = 0
        for (const d of ds) {
          if (d.user_level === 0 || moment(d.level_expire).diff(moment(), 'days') <= 5) {
            expireNum++
          }
        }
        this.expireNum = expireNum
      })
    },
    handleMenuClick (e) {
       switch (e.key) {
         case '1':
           this.showModal(QrBind, this.$t('user.action.scanBind'), 360, 400)
           break
         case '2':
           this.showModal(EmailBind, this.$t('user.action.emailBind'), 500, 500)
           break
       }
    },
    confirmDel (id) {
      deleteSharePermission({ id: id }).then(res => {
         this.refreshTable()
      })
    },
    showUserProfile (uid) {
      this.showModal(UserProfile, this.$t('user.profile'), 700, 600, { uid: uid })
    },
    search () {
      this.$refs.table.refresh()
    },
    upgrade (record) {
      this.showModal(MembershipUpgrade, this.$t('user.membership.upgrade'), 800, 460, { record: record })
    },
    switchMembership (record) {
      changeUserMembership({ userid: record.user_id, user_level: record.user_level === 2 ? 1 : 2 }).then(res => {
        this.refreshTable()
      })
    }
  }
}
</script>

<template>
  <div>
    <div>
      <div class="ant-card" style="padding: 8px;">
        <a-row :gutter="12">
          <a-col :lg="col.lg" :xl="col.xl">
            <div class="form-item">
              <span class="form-item-label">{{ $t('user.name') }}:</span>
              <a-input class="form-input" v-model="queryParams.name" allowClear></a-input>
            </div>
          </a-col>
          <a-col :lg="col.lg" :xl="col.xl">
            <div class="form-item">
              <a-button @click="search">{{ $t('user.action.search') }}</a-button>
            </div>
          </a-col>
          <a-col :lg="col.lg" :xl="12">
            <div >
              <a-dropdown class="float-right">

                <a-menu slot="overlay" @click="handleMenuClick">
                  <a-menu-item key="1"> <a-icon type="qrcode" />{{ $t('user.action.scanBind') }}</a-menu-item>
                  <!--                  <a-menu-item key="2"> <icon-font type="icon-Email" />{{ $t('user.action.emailBind') }}</a-menu-item>-->
                </a-menu>
                <a-button icon="link">
                  {{ $t('user.action.bind') }}
                </a-button>
              </a-dropdown>
            </div>
          </a-col>
        </a-row>
      </div>
    </div>
    <div class="ant-card" style="margin-top: 8px;">
      <a-alert
        v-if="expireNum>0"
        :message="'運転手'+expireNum+'人のPRO会員が期限切れになるか期限切れになる'"
        type="warning"
        closable
      />
      <s-table
        :columns="columns"
        :data="loadData"
        :locale="locale"
        ref="table"
        :scroll="{x:1320}">
        <span slot="status" slot-scope="text, record">
          <a-tag v-if="record.status===1" color="green">{{ $t('user.share.status.enable') }}</a-tag>
          <a-tag v-else color="red" :title="$t('user.share.status.disable.tip')">{{ $t('user.share.status.disable') }}</a-tag>
        </span>
        <span slot="expire" slot-scope="text, record">
          {{ record.user_level>0?moment(record.level_expire).format("YYYY-MM-DD HH:mm:ss"):'-' }}
        </span>
        <div slot="level" slot-scope="text, record">
          <div v-if="record.membership_switch_enable">
            <a-dropdown :trigger="['hover']">
              <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                <a-tag v-if="record.user_level===1" color="#FF9900">Pro会員<a-icon type="down"></a-icon></a-tag>
                <a-tag v-if="record.user_level===2" color="#FFCC00">Pro+会員<a-icon type="down"></a-icon></a-tag>
              </a>
              <a-menu slot="overlay">
                <a-menu-item key="0">
                  <a @click="switchMembership(record)" v-if="record.user_level===1" >Pro+会員に切り替え</a>
                  <a @click="switchMembership(record)" v-if="record.user_level===2">Pro会員に切り替え</a>
                </a-menu-item>
              </a-menu>
            </a-dropdown>
          </div>
          <div v-else>
            <a-tag v-if="record.user_level===0">試用会員</a-tag>
            <a-tag v-if="record.user_level===1" color="#FF9900">Pro会員</a-tag>
            <a-tag v-if="record.user_level===2" color="#FFCC00">Pro+会員</a-tag>
          </div>
        </div>
        <div slot="action" slot-scope="text, record">
          <a-popconfirm @confirm="confirmDel(record.id)" :title="$t('user.action.delete.tip')" :ok-text="$t('user.action.delete.yes')" :cancel-text="$t('user.action.delete.no')">
            <a href="#" style="color: red;">{{ $t('user.action.delete') }}</a>
          </a-popconfirm>
          <a-divider type="vertical"/>
          <a @click="showUserProfile(record.user_id)">{{ $t('user.profile') }}</a>
          <a-divider type="vertical"/>
          <a style="color: #FFCC00;" @click="upgrade(record)">{{ $t('user.membership.upgrade') }}</a>
        </div>
      </s-table>
    </div>
  </div>
</template>

<style scoped lang='less'>
.form-item{
  display: flex;
  .form-item-label{
    width: 60px;
  }

  .form-input{
    flex:1;
  }
}

</style>
